// /* You can add global styles to this file, and also import other style files */
// /* You can add global styles to this file, and also import other style files */
@import "./hub-legacy-styles/base/colors";
@import "./hub-legacy-styles/base/mixins";
@import "./hub-legacy-styles/components/flex-layouts";
@import "./hub-legacy-styles/components/footer";
@import "./hub-legacy-styles/components/context-nav";
@import "./hub-legacy-styles/components/buttons";
@import "./hub-legacy-styles/components/filters";
@import "./hub-legacy-styles/components/page-header";
@import "./hub-legacy-styles/components/typeahead";
@import "./hub-legacy-styles/templates/terms";
@import "./hub-legacy-styles/components/categories";
@import "./hub-legacy-styles/components/upa_list";
@import "./hub-legacy-styles/components/new-cb";
@import "./hub-legacy-styles/components/submenu";
@import "./hub-legacy-styles/components/multi-check-list";
@import "./hub-legacy-styles/components/language-picker";
@import "./hub-legacy-styles/components/program-utas";
@import "./hub-legacy-styles/components/media";
@import "./hub-legacy-styles/components/ua-tile";
@import "./hub-legacy-styles/components/saved-views";
@import "./hub-legacy-styles/components/context-utility-nav";
@import "./hub-legacy-styles/components/modal";
@import "./hub-legacy-styles/components/flight-search-results";
@import "./hub-legacy-styles/components/ua-inspect";
@import "./hub-legacy-styles/components/photos-filter";
@import "./hub-legacy-styles/components/login";
@import "./hub-legacy-styles/templates/upas";
@import "./hub-legacy-styles/templates/programs";
@import "./hub-legacy-styles/templates/gallery";
@import "./hub-legacy-styles/templates/settings";
@import "./hub-legacy-styles/admin";
@import "./hub-legacy-styles/components/brands-list";
@import "./hub-legacy-styles/components/market-utas";
@import "normalize";
@import "global";
@import "./app/hub-flight-search/hub-flight-search.style";
@import "ag-grid.scss";

html {
  height: 100%;
}

body {
  min-height: 100vh;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.header-logo {
  &__container {
    height: 56px;
    display: block;
    padding: var(--space-s);
  }
}

.header__logo {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 20px !important;
  line-height: 24px;

  a {
    color: var(--color-button-text-inverse-enabled-medium) !important;
  }
}

.content-container {
  padding: var(--space-m) 0;
}

.chart-content-container {
  padding: var(--space-m) 16;
  border: #ccc 1px solid;
}

button {
  border: none;
}

.side-column {
  background-color: var(--color-sidebar-fill-primary-enabled-weak) !important;
  border-right: none !important;
  position: sticky;
  top: 0;
  z-index: 99;
  height: 100vh;
  width: 205px;
  max-width: 205px;

  &__account {
    .block {
      &__img,
      &__headline {
        color: var(--color-button-text-inverse-enabled-medium) !important;
      }

      &__img {
        font-size: 24px;
        border-radius: 10px;
        padding: 5px;
        width: auto;
      }
    }
  }
}

.main-content {
  background-color: var(--color-element-fill-inverse-enabled-weak);
}

.reveal-modal {
  .options {
    display: flex !important;
    justify-content: space-around;
  }
}

.grid-item .grid-item-actions {
  border-top: none;

  &:hover {
    border-top: none;
  }
}

.rh-flex-layout {
  height: auto;
  overflow-y: visible;
  overflow-x: visible;
}

.utility-nav {
  margin-top: 0;
}

.saved-views {
  &__container {
    height: auto;
  }

  &__actions {
    position: fixed;
    bottom: 0;
    width: 205px;
  }
}

.avatar-menu {
  &__dropdown {
    z-index: 1000;
  }
}

.simple-dropdown__button {
  width: 100%;
}

.filter__item,
.cabins__item {
  float: none !important;
  line-height: 20px;
  margin-bottom: 5px;
  padding: 5px;
}

.filter__hd {
  line-height: 20px;
}

.table__search {
  &-input {
    display: block;
    width: 250px;
  }

  &-container {
    padding: var(--space-m) var(--space-m) 0 var(--space-m);
  }
}

rh-ui-menu-container .simple-dropdown__list .simple-dropdown__item {
  float: none !important;
}

.media-selection {
  footer {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 15px;
  }
}

.page-header .page-actions > li {
  line-height: 40px;
  height: 40px;
}

.field--horizontal .field__label {
  align-self: center;
  padding-top: 0;
}

.brands__item .field__label {
  display: none;
}

.icon {
  fill: currentColor !important;
}

.modal-side--open.modal--large {
  right: 0;
  left: unset !important;
  top: 0;
  bottom: 0;
  height: 100vh;
}

#upas_basics .unselected {
  width: 100%;
}

.alert {
  color: var(--color-button-text-danger-enabled-medium);
  border-color: var(--color-button-border-danger-enabled-medium) !important;
}

.notice {
  color: var(--color-button-text-secondary-enabled-medium);
  border-color: var(--color-button-text-secondary-enabled-medium) !important;
}

rh-ui-spinner {
  display: inline-block;
}

.radio-group--horizontal {
  .radio__container {
    display: flex;
    gap: 15px;
  }
}

.photo-left {
  display: flex;
  flex-wrap: wrap;
  float: left;
  padding: 20px;
}

.textaria-right {
  float: right;
  display: grid;
  flex-grow: 1;
}

.textaria-input {
  max-width: 400px;
  height: 80px;
  display: flex;
  resize: vertical;
}

.wrapper {
  display: flex;
  padding-inline: var(--space-m);
}

.form-rh textarea {
  resize: none;
}

.sortable-upa-media {
  display: flex;
  flex-wrap: wrap;
  resize: vertical;
  margin-bottom: var(--space-s);
}

.rh-glyphs-close,
.rh-glyphs-list-view,
.rh-glyphs-grid-view {
  color: #536277;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  position: static;
  top: 60px;
  right: 400px;
  transition: color 0.2s ease-out;
}

.fs-input-location {
  display: inline-block;
  height: 40px;
  width: 100%;
  vertical-align: top;
}

.self-align {
  &--top {
    align-self: flex-start;
  }

  &--center {
    align-self: center;
  }
}

.text--left {
  text-align: left;
}

.slideover-link {
  cursor: pointer;
  font-weight: 500;
  color: var(--color-button-fill-primary-enabled-medium);
  &:hover {
    color: var(--color-button-fill-primary-hover-medium);
  }
  &:pressed {
    color: var(--color-button-fill-primary-pressed-medium);
  }
}

section.view {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.targeting-lists {
  &__list{
    border-left: 1px solid var(--color-element-border-primary-enabled-medium);
    border-right: 1px solid var(--color-element-border-primary-enabled-medium);
  }
  &__item {
    list-style: none;
    height: var(--space-xxl);
    border-top: 1px solid var(--color-element-border-primary-enabled-medium);
    margin: 0;
    display: flex;
    align-items: center;
    padding: var(--space-xs) var(--space-s);
    transition: all 0.2s ease-out;

    &:last-child {
      border-bottom: 1px solid var(--color-element-border-primary-enabled-medium);
    }
  }

  &__item-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: space-between;
  }

  &__name {
    flex-grow: 2;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    padding: 0;

    .icon {
      height: 12px;
      width: 12px;
      margin-right: 4px;
    }
  }

  &__actions {
    width: 100px;
  }

  &__action {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    margin: 0 12px 0 0;
    cursor: pointer;
  }
}

.targeting-lists__name {
  font-weight: 600;
}

.targeting-list__item--empty .targeting-lists__name {
  background-image: none;
  color: #536277;
  font-weight: 400;
}

.user_avatar__label {
  opacity: 0.7;
}

[hidden],
template {
  display: none;
}

.panel__list.menu__list {
  .panel__item.menu__item {
    overflow: hidden;

    &--active {
      .menu__button {
        background-color: transparent;
      }
    }
  }
}

.green-check-overlay {
  position: absolute;
  background: #4D7A14;
  color: white;
  border-radius: 50%;
  top: -12px;
  right: -12px;
  padding: 4px;

}

.tooltip-popup {
  @include box-shadow-medium;
  border: 2px solid #ecedef;
  background-color: #fff;
  padding: 8px;
  border: 2px;
  gap: 16px;
  position: absolute;
  top: 0;
  max-width: 530px;
  z-index: 1100;
}

// styles to force a scrollbar on the sidebar nav component
// this is currently needed due to the nested element setup of the sidebar nav
.rh-sidebar-nav::-webkit-scrollbar {
  width: 11px;
}

.rh-sidebar-nav::-webkit-scrollbar-track {
  background: transparent;
}

.rh-sidebar-nav::-webkit-scrollbar-thumb {
  background-color: rgba(130, 139, 151, 1);
  border-radius: 50px;
  border: 2px solid var(--color-sidebar-fill-primary-enabled-weak);
}

.layout-container-v2 .main-content:not(.main-content--full) {
  padding: 0 var(--space-l) !important;
}

rh-section-tabs + div {
  margin-top: 1rem;
}

.media-widget {
  padding-block: var(--space-s);
}

.NgxEditor__MenuBar {
  border-radius: 4px;
  background: transparent !important;
}

.NgxEditor {
  border: none !important;
  background: transparent !important;
  * ul,
  * ol {
    list-style-position: inside;
    * > p {
      display: inline;
    }
    * > ul,
    * > ol {
      padding-left: 16px;
    }
  }
  * ul {
    list-style-type: disc;
  }
  * ul ul {
    list-style-type: circle;
  }

  * ul ul ul {
    list-style-type: square;
  }
}

.NgxEditor__Content {
  padding: 8px 12px !important;
  height: 136px;
  overflow-y: scroll;
}

/***
  *  Hub UI v3 Migration Hacks
  */

.content--padded-inline {
  padding-inline:  var(--space-l);
}
.standard-page-content{
  padding: var(--space-s) var(--space-l);
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}
.content-container--with-messages {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}
.spinner-container {
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rh-ui-spinner {
  width: fit-content;
}
.spinner-row {
  display: grid;
  place-items: center;
  height: calc(100vh - var(--app-header-height) - var(--app-local-header-height) - 80px); // 80px = footer height
}

// Page header Hack for sticky headers until we can refactor these away
.page-header--sticky.page-header--v3 {
  padding-top: var(--space-s);
  min-height: calc(40px + var(--space-s)); // normal height plus padding
  background-color: var(--color-element-fill-inverse-enabled-weak);
}

// UPA Summary View
.upas-summary__content {
  padding: 0 var(--space-l) var(--space-s);
}

.ua-tile {
  &:hover {
    .ua-tile__body {
      @include box-shadow-light;
    }
  }
}
// UPA Edit Pages
.upa-workflow-actions__list {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--space-xs);
  @include no-bullet;
}

.field__help {
  min-height: calc(12px + var(--space-xs));
}
// Galleries
.gallery-code-input-block {
  &.active {
    @include box-shadow-light;
    align-items: flex-end;
    border-radius: var(--border-radius-m);
    display: flex;
    flex-direction: column;
    gap: var(--space-xxs);
  }
  .gallery-embed-code-input,
  .gallery-share-code-input {
    box-shadow: none;
  }
}

.app-messages {
  display: none;
  &:has(li) {
    display: block;
  }
}
.chart-content__container {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  padding: var(--space-s) 0;
  .chart-categories__row{
    padding: calc(var(--space-s) + var(--space-l)) 0 0 0;
    display: flex;
    gap: var(--space-m);
    align-items: flex-end;
    &--bottom{
      padding-left: var(--space-s);
    }
  }

  .chart-actions__row {
    display: flex;
    justify-content: space-between;
  }
  .chart-actions-buttons__row {
    display: flex;
    gap: var(--space-m);
    align-items: flex-start;
    justify-content: flex-end;
    flex-grow: 1;
  }

  .chart-actions-buttons__row-first {
    flex-grow: 1;
    display: flex;
    gap: var(--space-m);
    align-items: flex-start;
    .show-all-button{
      padding-top: 28px;
    }
  }

  .chart-actions__dropdown-field {
    max-width: 288px;
    flex-grow: 1;
  }
  .chart-actions__field {
    max-width: 177px;
    flex-grow: 1;
  }
  .chart-actions__date-range {
    max-width: 374px;
    flex-grow: 1;
  }
}


.metrics-info-bar {
  &:empty {
    display: none;
  }
}

.adjusted-metrics-table {
  .slide-over__content {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
  }
  .table {
    width: 100%;
    height: fit-content;
    &:not(.table--flat) {
      box-shadow: none;
    }
    table-layout: auto;
    overflow-y: auto;

  .table__row {
    background-color: inherit;
  }
  .table__heading,
  .table__cell {
    border-bottom: none;
  }
  .table__cell:not(:first-child),
  .table__heading:not(:first-child) {
    &:not(:last-child) {
      border-right: 1px solid #CCC;
    }
      text-align: right;
    }
  }
}

.standard-page-content{
  padding: var(--space-s) var(--space-l);
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}
.content-container--with-messages {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}
.input-dropdown-readonly {
  &.input {
    &:read-only{
      cursor: pointer;
      background-color: var(--color-element-fill-inverse-enabled-weak);
    }
  }
}
.chart-categories__title {
  font-weight: 500;
}
.targeting-rules {
  @include no-bullet;
  > li:not(.last-child){
    margin-bottom: var(--space-xs);
  }
  .duration-container,
  .value-container {
    float: left;
    border: 1px solid var(--color-element-border-primary-enabled-medium);
    background-color: #fff;
    font-size: 14px;
    border-radius: 4px;
    padding: var(--space-xs);
    height: 36px;

    &.has-error {
      border-color: $alert;
    }

    input,
    label {
      border: 0;
      display: inline;
      margin: 0;
      padding: 0;
      line-height: 18px;
      text-align: right;
      width: 26px;
      &:focus {
        outline: none;
      }
    }
    .duration-divider {
      margin: 0;
      padding: 0;
      line-height: 18px;
      text-align: center;
    }
    input {
      margin-right: 2px;
    }
    label {
      padding-right: 6px;
    }
  }
  .range-dash {
    float: left;
    line-height: 36px;
    text-align: center;
    width: 22px;
  }
}

.slide-over--grid {
  background-color: var(--color-element-fill-inverse-enabled-weak);
}

.gallery-actions {
  display: flex;
  align-items: center;
  gap: var(--space-s);
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: var(--space-s);
  visibility: visible;
  &.gallery-actions {
    opacity: 1;
    visibility: visible;
  }
}

// hack necessary until the Targeting view uses a different component than the shared 'categories' component
.gallery-meta-view {
  .all-categories {
    .rh-button {
      border: 1px solid var(--color-button-border-primary-enabled-medium) !important;
      @include paragraph-s;
      height: calc(var(--space-l) + var(--space-xxs));
      padding: 0 var(--space-xs);

      &:hover {
        border: 1px solid var(--button-color-hover, --color-button-border-primary-hover-medium) !important;
        background-color: var(--color-button-fill-inverse-enabled-medium);
        color: var(--button-color-hover, --color-button-text-primary-hover-medium);
        text-decoration: none;
      }
    }
  }
}

.translations_dropdown.rh-menu__content{
  color: var(--color-text-primary);
  right: 0px;
  .button__text {
    display: inline;
  }
}
.extreme-z-index {
  &.extreme-z-index {
    z-index: calc(9999999 + 1);
  }
}
.change-history-grid {
  .ag-cell-value {
    @include paragraph-m;
  }
  .ag-cell{
    line-height: 22px;
    padding: 12px;
  }
}

.icon-fs-seat {
  opacity: 60%;
}

.check-dropdown-overlay {
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  z-index: 99;
}

.checkbox-cell-padding {
  padding-left: 17px;
}

.upa-details {
  .field-m {
    max-width: 360px;
    min-width: 300px;
    flex: 1;
    padding-right: 22px;
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
  }
  .add-element-button {
    margin-left: -8px;
  }
  .icon {
    cursor: pointer;
  }
}

// utility classes
.text--center {
  text-align: center;
}

.element--center {
  margin: 0 auto;
  text-align: center;
}

.hub-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.hub-col {
  flex: 1;
}

.hub-col-10 {
  flex: 0 0 10%;
  width: 10%;
}

.hub-col-20 {
  flex: 0 0 20%;
  width: 20%;
}

.hub-col-25 {
  flex: 0 0 25%;
  width: 25%;
}

.hub-col-33 {
  flex: 0 0 33%;
  width: 33%;
}

.hub-col-35 {
  flex: 0 0 35%;
  width: 35%;
}

.hub-col-45 {
  flex: 0 0 45%;
  width: 45%;
}

.hub-col-50 {
  flex: 0 0 50%;
  width: 50%;
}

.hub-col-55 {
  flex: 0 0 55%;
  width: 55%;
}

.hub-col-60 {
  flex: 0 0 60%;
  width: 60%;
}

.hub-col-65 {
  flex: 0 0 65%;
  width: 65%;
}

.hub-col-66 {
  flex: 0 0 66%;
  width: 66%;
}

.hub-col-75 {
  flex: 0 0 75%;
  width: 75%;
}

.hub-col-80 {
  flex: 0 0 80%;
  width: 80%;
}

.hub-col-90 {
  flex: 0 0 90%;
  width: 90%;
}

.hub-col-100 {
  flex: 0 0 100%;
  width: 100%;
}

.padding-bottom-24 {
  padding-bottom: 24px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}